import React, { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "./orderList.css";
// import { userLogin } from '../../services/api/login.request'
import { Radio, Layout, Table, Button, Space, Tag, Pagination } from "antd";
import { getOrderList, getOrderDetail, getAnnualRegister, setAnnualBranch, getOrderDetailInfo } from "../../services/api/registerInfo.request";

// import { userLogin, getCode, codeLogin, forgetPassword } from '../../services/api/login.request'

const { Header, Sider, Content } = Layout;

const contentStyle = {
  textAlign: "center",
  color: "#fff",
  backgroundColor: "#f2f2f2",
};
const siderStyle = {
  textAlign: "center",
  color: "#fff",
  backgroundColor: "#015cce",
  overflow: "hidden",
};


const OrderList = () => {
  const pagination = true
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(7);
  const [type, setType] = useState(0);
  const [total, setTotal] = useState(null);
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };


  const doOrderList = async (current = 1, size = 7, type = null) => {
    console.log(current, size)
    console.log(type)
    const company = await getOrderList({
      progress: 0,
      queryValue: "",
      type,
      pageNum: current,
      pageSize: size,
    });
    if (company.code === 200) {
      setTotal(company.data.total);
      setList(company.data.list);
    }
  };

  const onChange = (current, size) => {
    setPage(current);
    setSize(size);
    doOrderList(current, size, type)
  };

  const changeRadio = (item) => {
    setType(item.target.value === 'a' ? null : item.target.value)
    doOrderList(1, 7, item.target.value === 'a' ? null : item.target.value)
  }


  const goRegisterinfo = (col, row) => {
    console.log(row)
    window.localStorage.setItem("row", JSON.stringify(row))
    navigate('/registerInfo');
  }


  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
      return;
    }
    doOrderList();
  }, []);


  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const headeStyle = {
    display: "flex",
    alignItems: "center",
    background: "#fff",
  };



  const columns = [
    {
      title: '订单标号',
      dataIndex: 'orderNo',
      key: 'orderNo',
      width: '10%',
    },
    {
      title: '业务类型',
      dataIndex: 'planName',
      key: 'planName',
      width: '15%',
    },
    {
      title: '公司名称',
      dataIndex: 'registeredCompanyName',
      key: 'registeredCompanyName',
      width: '15%',
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
      render: (col) => {
        return moment(Number(col || 0)).format("YYYY-MM-DD HH:mm:ss");
      },
      width: '10%',
    },
    {
      title: '资料完整度',
      dataIndex: 'status',
      key: 'status',
      width: '10%',

      render: (col, row) => (
        row.status == 3 ? <Tag color="success">100%</Tag> : <Tag color="error">l0%</Tag>
      )
    },
    {
      title: '是否完成签署',
      dataIndex: 'status',
      key: 'status',
      width: '10%',

      render: (col, row) => (
        row.status == 3 ? <Tag color="success">已签署</Tag> : <Tag color="error">未签署</Tag>
      )
    },

    {
      title: '待办',
      dataIndex: 'address',
      key: 'address',
      render: (col, row) => (
        row.step !== 4 ? <Space>
          <Button
            type="primary"
            onClick={() => goRegisterinfo(col, row)}
          >
            去填写
          </Button>

        </Space> :


          <Space>
            <Button

            >
              已完成
            </Button>

          </Space>
      ),
      width: '5%',
    },


  ];

  return (
    <div className="body">
      <Header className="header" style={headeStyle}>
        <img src={require("../../utils/logohei01.png")} />

        <span>
          欢迎回来,{userInfo.name || "--"}（{userInfo.mobile || "--"}）
        </span>

        <Button type="link" onClick={logout}>
          退出登录
        </Button>

      </Header>

      <div className="bottom">
        <Layout className="bottom">
          <Sider width="10%" height="100%" style={siderStyle}>
            <div className="siderLeft"> 待办中心 </div>
          </Sider>

          <Content height="100%" style={contentStyle}>

            <div className="Content_box">
              <div className="Content_tit">待办中心</div>

              <div className="Content_toptab">
                <Radio.Group defaultValue="a" buttonStyle="solid" onChange={changeRadio}>
                  <Radio.Button value="a" className="Content_toptab_one">全部订单</Radio.Button>
                  <Radio.Button value="0" className="Content_toptab_one">香港公司注册</Radio.Button>
                  <Radio.Button value="1" className="Content_toptab_one">现成公司购买</Radio.Button>
                  <Radio.Button value="2" className="Content_toptab_one">美国公司注册</Radio.Button>
                  <Radio.Button value="7" className="Content_toptab_one">英国公司注册</Radio.Button>
                  <Radio.Button value="3" className="Content_toptab_one">香港银行开户</Radio.Button>
                  <Radio.Button value="5" className="Content_toptab_one">转秘书年审</Radio.Button>
                  {/* <Radio.Button value="6" className="Content_toptab_one">年审</Radio.Button> */}
                </Radio.Group>
              </div>

              <div className="Content_tabel">
                <Table rowKey="id" dataSource={list} columns={columns} pagination={false} />
              </div>

              {total > 0 && pagination ? (
                <div className="paginationStyle">
                  <Pagination showSizeChanger current={page} pageSize={size} type={type} total={total} showTotal={() => "共" + total + "条数据"} onChange={onChange} />
                </div>
              ) : null}

            </div>
          </Content>
        </Layout>
      </div>

    </div>
  );
};

export default OrderList;
